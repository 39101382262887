import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout";
import "../styles/pages/blog.scss";

const BlogPage = () => {

    const posts = useStaticQuery(graphql`
        query {
            allMarkdownRemark {
                edges {
                    node {
                        frontmatter {
                            title
                            date
                        }
                        id
                        excerpt (pruneLength: 100, truncate: false)
                        wordCount {
                            words
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `);

    return (
        <Layout>
            <div className="blog-layout">
                <h1>My personal thought</h1>
                <p>Feel free to read and share my personal thoughts on development.</p>

                <div className="articles-container">
                    {posts.allMarkdownRemark.edges.map((edge) => {

                        return (
                            <Link to={`${edge.node.fields.slug}`}>
                                <article id={edge.node.id}>
                                    <h1>{edge.node.frontmatter.title}</h1>
                                    <p>{edge.node.excerpt}</p>
                                    <p className="words">{edge.node.wordCount.words} words in this post</p>
                                    <p className="date">Posted on: {edge.node.frontmatter.date}</p>
                                </article>
                            </Link>
                        );

                    })}
                </div>

            </div>
        </Layout>
    )
}

export default BlogPage
